import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";

// Layout
import SecondaryLanding from "../../layouts/secondary-landing";

// Components
import HeroChevron from "../hero/hero-chevron";
import BreadcrumbAuto from "../breadcrumb/breadcrumb-auto";
import SEO from "../seo/seo";
import BranchSearch from "../branch-search/branch-search";
import CustomMiniBand from "../mini-band/mini-band-custom";
import OnlineEnrollmentModal from "../custom-widgets/online-enroll-modal";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";

export const query = graphql`
  query spanishStateHeroQuery(
    $slug: String
    $pathXXL: String
    $pathXL: String
    $pathLG: String
    $pathMD: String
    $pathSM: String
    $pathXS: String
    $pathXXS: String
  ) {
    imgVariableXXL: file(relativePath: { eq: $pathXXL }) {
      ...heroChevronImageFragmentXXL
    }
    imgVariableXL: file(relativePath: { eq: $pathXL }) {
      ...heroChevronImageFragmentXL
    }
    imgVariableLG: file(relativePath: { eq: $pathLG }) {
      ...heroChevronImageFragmentLG
    }
    imgVariableMD: file(relativePath: { eq: $pathMD }) {
      ...heroChevronImageFragmentMD
    }
    imgVariableSM: file(relativePath: { eq: $pathSM }) {
      ...heroChevronImageFragmentSM
    }
    imgVariableXS: file(relativePath: { eq: $pathXS }) {
      ...heroChevronImageFragmentXS
    }
    imgVariableXXS: file(relativePath: { eq: $pathXXS }) {
      ...heroChevronImageFragmentXXS
    }
    strapiBranchState: strapiBranchStates(Slug: { eq: $slug }) {
      StateCode
      StateName
      Slug
      StateGeoLocation {
        Lat
        Lng
        Zoom
      }
      branch_locations {
        Title
      }
    }
  }
`;

const SpanishStateBranchesTemplate = ({ location, data }) => {
  const stateData = data.strapiBranchState;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //console.log("stateData: ", stateData);

  const StateName = stateData.StateName === "New Mexico" ? "Nuevo Mexico" : stateData.StateName;
  const StateCode = stateData.StateCode;

  const pageLocation = { location };

  const SearchData = {
    isSpanish: true,
    StateName: StateName,
    StateCode: stateData.StateCode,
    GMData: {
      center: {
        lat: stateData.StateGeoLocation.Lat,
        lng: stateData.StateGeoLocation.Lng
      },
      zoom: stateData.StateGeoLocation.Zoom,
      title: StateName,
      markers: []
    }
  };

  const metaTitle = "Ubicación de sucursales de banco en " + StateName;
  const metaDescription =
    "Le damos la bienvenida a WaFd Bank de " +
    StateName +
    ". Trabajamos con cuentas de cheques, cajas de ahorros e hipotecas en las comunidades donde brindamos servicios. Visite una sucursal cercana en " +
    StateName +
    " hoy mismo.";
  const fullPageUrl = `https://www.wafdbank.com/es/sucursales/${stateData.Slug.replace(/new-mexico/, "nuevo-mexico")}`;

  const heroData = {
    AZ: { imgDateStamp: "04132023", altText: "Downtown skyline in Tucson, Arizona" },
    CA: { imgDateStamp: "02082024", altText: "Downtown San Jose, California city skyline." },
    ID: { imgDateStamp: "04132023", altText: "Downtown skyline in Boise, Idaho with hot air balloons" },
    NM: { imgDateStamp: "04132023", altText: "Downtown skyline if Albuquerque, New Mexico" },
    NV: { imgDateStamp: "09112023", altText: "Las Vegas skyline and residential area" },
    OR: { imgDateStamp: "04132023", altText: "Downtown skyline in Portland, Oregon" },
    TX: { imgDateStamp: "04132023", altText: "Downtown skyline of El Paso, Texas" },
    UT: { imgDateStamp: "04132023", altText: "Downtown skyline in Salt Lake City, Utah" },
    WA: { imgDateStamp: "04132023", altText: "Downtown Seattle skyline with Space Needle" }
  };

  const SEOData = {
    title: metaTitle,
    meta: [
      {
        name: "title",
        property: "og:title",
        content: metaTitle
      },
      {
        name: "description",
        property: "og:description",
        content: metaDescription
      },
      {
        property: "og:url",
        content: fullPageUrl
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content:
          "https://www.wafdbank.com/images/adsearch/states/og-locations-" +
          stateData.Slug +
          "-" +
          heroData[StateCode].imgDateStamp +
          ".jpg"
      }
    ]
  };

  const heroChevronData = {
    id: "es-state-branches-hero",
    ...getHeroImgVariables(data),
    altText: heroData[StateCode].altText,
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Sucursales de WaFd Bank en " + StateName
          }
        },
        {
          id: 2,
          button: {
            id: "state-branches-hero-cta",
            class: "btn-white",
            text: "Encuentra una sucursal",
            type: "anchor-link",
            url: "#branch-search-section"
          }
        }
      ]
    }
  };

  // In the introduction content, we are listing a few branches from each state randomly
  // Texas has only 5 branches, so the maximum number could only be 4.
  const stateBranchLocation1 = stateData.branch_locations[1];
  const stateBranchLocation2 = stateData.branch_locations[2];
  const stateBranchLocation3 = stateData.branch_locations[3];

  const cityPageLinks = {
    WA: {
      city1Name: "Seattle",
      city1Url: "/es/sucursales/washington/seattle",
      city2Name: "Bellingham",
      city2Url: "/es/sucursales/washington/bellingham"
    },
    ID: {
      city1Name: "Boise",
      city1Url: "/es/sucursales/idaho/boise",
      city2Name: "Nampa",
      city2Url: "/es/sucursales/idaho/nampa"
    },
    CA: {
      city1Name: "El Segundo",
      city1Url: "/es/sucursales/california/el-segundo",
      city2Name: "Long Beach",
      city2Url: "/es/sucursales/california/long-beach"
    },
    NV: {
      city1Name: "Las Vegas",
      city1Url: "/es/sucursales/nevada/las-vegas",
      city2Name: "Elko",
      city2Url: "/es/sucursales/nevada/elko"
    },
    UT: {
      city1Name: "Salt Lake City",
      city1Url: "/es/sucursales/utah/salt-lake-city",
      city2Name: "Price",
      city2Url: "/es/sucursales/utah/price"
    },
    AZ: {
      city1Name: "Phoenix",
      city1Url: "/es/sucursales/arizona/phoenix",
      city2Name: "Tucson",
      city2Url: "/es/sucursales/arizona/tucson"
    },
    NM: {
      city1Name: "Albuquerque",
      city1Url: "/es/sucursales/nuevo-mexico/albuquerque",
      city2Name: "Santa Fe",
      city2Url: "/es/sucursales/nuevo-mexico/santa-fe"
    },
    TX: {
      city1Name: "Dallas",
      city1Url: "/es/sucursales/texas/dallas",
      city2Name: "Plano",
      city2Url: "/es/sucursales/texas/plano"
    },
    OR: {
      city1Name: "Eugene",
      city1Url: "/es/sucursales/oregon/eugene",
      city2Name: "Portland",
      city2Url: "/es/sucursales/oregon/portland"
    }
  };

  const enrollModal = {
    id: "online-enroll-modal",
    handleClose,
    show
  };

  const miniBandData = {
    sectionClass: "bg-light pt-0",
    rowColsClass: "row-cols-1 row-cols-md-2 row-cols-lg-4 no-gutters",
    cards: [
      {
        id: 1,
        class: "bg-light",
        colClass: "col mb-4",
        showIcon: true,
        icon: {
          lib: "fal",
          name: "money-bill-wave",
          class: "fa-3x"
        },
        titleClass: "text-center",
        title: "Abra una cuenta en línea",
        textClass: "text-center",
        text: "Abra una cuenta personal en línea",
        button: {
          id: "personal-account-cta-button",
          showIcon: false,
          class: "btn-primary stretched-link",
          text: "Abrir una cuenta personal",
          url: "/es/abrir-cuenta-bancaria-en-linea"
        }
      },
      {
        id: 2,
        class: "bg-light",
        colClass: "col mb-4",
        showIcon: true,
        icon: {
          lib: "fal",
          name: "briefcase",
          class: "fa-3x"
        },
        titleClass: "text-center",
        title: "Tarjetas de crédito de WaFd Bank",
        textClass: "text-center",
        text: "Conozca qué tarjeta de crédito de WaFd Bank funciona mejor para usted.",
        button: {
          id: "business-account-cta-button",
          class: "btn-primary stretched-link",
          showIcon: false,
          text: "Aprende más",
          url: "/es/banca-personal/tarjetas-de-credito"
        }
      },
      {
        id: 3,
        class: "bg-light",
        colClass: "col mb-4",
        showIcon: true,
        icon: {
          lib: "fal",
          name: "tv",
          class: "fa-3x"
        },
        titleClass: "text-center",
        title: "¿Ya tienes una cuenta?",
        textClass: "text-center",
        text: "Regístrese para acceder en línea",
        button: {
          id: "online-banking-cta-button",
          type: "button",
          showIcon: false,
          class: "btn-primary stretched-link",
          text: "Inscríbase en la banca en línea",
          onClick: handleShow
        }
      },
      {
        id: 4,
        class: "bg-light",
        colClass: "col mb-4",
        showIcon: true,
        icon: {
          lib: "fal",
          name: "home",
          class: "fa-3x"
        },
        titleClass: "text-center",
        title: "¿Comprar o refinanciar una vivienda?",
        textClass: "text-center",
        text: "¡Póngase en contacto con el representante bancario de su sucursal local!",
        button: {
          id: "mortgage-cta-button",
          type: "link",
          showIcon: false,
          class: "btn-primary stretched-link no-max-width",
          text: "Póngase en contacto con un oficial de préstamos",
          url: "/es/banca-personal/contacte-a-un-asesor-hipotecario"
        }
      }
    ]
  };

  return (
    <SecondaryLanding>
      <HeroChevron {...heroChevronData} />
      <BreadcrumbAuto {...pageLocation} />
      <SEO {...SEOData} />
      {show && <OnlineEnrollmentModal {...enrollModal} />}

      <div id="state-branches-wrapper">
        <section id="state-branches-overview-section" className="bg-white">
          <div className="container">
            <h1>{StateName}</h1>
            {StateCode === "CA" ? (
              <h3>
                La gente del Golden State confía en WaFd Bank para todas sus necesidades bancarias personales y
                comerciales. Ya sea que esté buscando abrir una cuenta comercial en{" "}
                <Link to={cityPageLinks[StateCode].city1Url}>{cityPageLinks[StateCode].city1Name}</Link> o una cuenta de
                cheques gratuita en{" "}
                <Link to={cityPageLinks[StateCode].city2Url}>{cityPageLinks[StateCode].city2Name}</Link>, estamos aquí
                para servirle. Durante más de 100 años, WaFd Bank ha prestado servicios a nuestras comunidades en
                Occidente.
              </h3>
            ) : (
              <h3>
                La gente del gran estado de {StateName} confía en WaFd Bank para todas sus necesidades bancarias
                personales. Ya sea que esté buscando una hipoteca en{" "}
                <Link to={cityPageLinks[StateCode].city1Url}>{cityPageLinks[StateCode].city1Name}</Link> o una cuentas
                de ahorro en <Link to={cityPageLinks[StateCode].city2Url}>{cityPageLinks[StateCode].city2Name}</Link>,
                estamos aquí para servirle. Durante más de 100 años, los ciudadanos de {StateName} han confiado en
                nosotros para ayudar a asegurar el futuro de sus familias.
              </h3>
            )}
          </div>
        </section>
        <BranchSearch {...SearchData} />
        {StateCode !== "CA" && (
          <>
            <section className="bg-light pb-3">
              <div className="container">
                <h3>WaFd Bank {StateName} en línea</h3>
                <p>
                  Los residentes del gran estado de {StateName} confían en WaFd Bank para sus necesidades bancarias
                  personales. Servimos a nuestros clientes en el {StateName} con más de{" "}
                  {stateData.branch_locations.length} ubicaciones que se extienden desde {stateBranchLocation3.Title}{" "}
                  hasta {stateBranchLocation2.Title} y {stateBranchLocation1.Title}. Durante más de 100 años, las
                  personas han confiado en nosotros para ayudar a asegurar el futuro de sus familias.
                </p>
                <p>
                  Estamos aquí para simplificar sus operaciones bancarias. Comenzando con{" "}
                  <Link to="/es/banca-personal/cuenta-de-cheques">Cheque gratuito</Link>, apertura de cuenta en línea,
                  depósito móvil y Tarjetas de débito sin contacto, estamos disponibles las 24 horas del día, los 7 días
                  de la semana para hacer que la banca sea rápida y fácil. Visite una de nuestras sucursales hoy para
                  hablar con un banquero amigable o{" "}
                  <Link to="/es/abrir-cuenta-bancaria-en-linea">Abra una cuenta en línea</Link>. Permítanos ganarnos su
                  negocio.
                </p>
              </div>
            </section>
            <CustomMiniBand {...miniBandData} />
          </>
        )}
      </div>
    </SecondaryLanding>
  );
};

SpanishStateBranchesTemplate.propTypes = {
  branch: PropTypes.object
};

export default SpanishStateBranchesTemplate;
